import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Release v2020.10.27`}</h2>
    <h3>{`Features`}</h3>
    <p><strong parentName="p">{`Customer Settings Tab:`}</strong></p>
    <p>{`The Customer Settings tab on Customer Details page contains a list of customer parameters.`}</p>
    <p>{`Each parameter has a label and a text field with the current value.`}</p>
    <p>{`The user can edit any listed parameter value.`}</p>
    <h3>{`Bug Fixes`}</h3>
    <p><em parentName="p">{`Bugs resolved in this release were found during development:`}</em></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://tfs.carefusion.com/tfs/DefaultCollection/DaVinci/_workitems#_a=edit&id=1173215"
        }}>{`Bug 1173215`}</a>{`: Action progress crashes the page`}</p>
    </blockquote>
    <p><em parentName="p">{`Resloution:`}</em>{` Some `}<inlineCode parentName="p">{`Execute Actions`}</inlineCode>{` steps would return a `}<inlineCode parentName="p">{`null`}</inlineCode>{` status, causing the UI to crash. Any `}<inlineCode parentName="p">{`null`}</inlineCode>{` returns are now ignored.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://tfs.carefusion.com/tfs/DefaultCollection/DaVinci/_workitems#_a=edit&id=1173217"
        }}>{`Bug 1173217`}</a>{`: Station upgrade execution status updates are not automatically refreshing anymore`}</p>
    </blockquote>
    <p><em parentName="p">{`Resolution:`}</em>{` Update station update credentials`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      